import { useCartStore } from "@/store/cart";
import { useEffect, useState } from "react";
import { FaLeftLong, FaCartArrowDown } from "react-icons/fa6";

import { FiDelete, FiEdit, FiTrash } from "react-icons/fi";
export const CartBar = () => {
  const [show, setShow] = useState(false);

  const { cart, removeFromCart, clearCart, updateCart } = useCartStore(
    (state) => state
  );

  useEffect(() => {
    if (show) {
      // document.getElementById("arrowButton").classList.remove("rotate-arrow");
      document
        .getElementById("floating-right-bar")
        .classList.replace("right-[-80%]", "right-[0%]");
      document
        .getElementById("floating-right-bar")
        .classList.replace("md:right-[-470px]", "md:right-[0px]");
    } else {
      // document.getElementById("arrowButton").classList.add("rotate-arrow");
      document
        .getElementById("floating-right-bar")
        .classList.replace("right-[0%]", "right-[-80%]");
      document
        .getElementById("floating-right-bar")
        .classList.replace("md:right-[0px]", "md:right-[-470px]");
    }
  }, [show]);

  useEffect(() => {
    document.getElementById("arrowButton").classList.add("animate-arrow");
    document
      .getElementById("arrowButton")
      .addEventListener("animationend", () => {
        document
          .getElementById("arrowButton")
          .classList.remove("animate-arrow");
      });
  }, [cart]);

  const handleShow = () => {
    setShow(!show);
  };
  const calculateTotal = () => {
    let total = 0;
    cart.forEach((product) => {
      total += Number(product.price);
    });
    return total;
  };

  return (
    <div
      className="floatingCart fixed right-[-80%] md:right-[-470px] top-0 h-full w-[80%] md:w-[470px] bg-figma-secondary transition-all duration-700 shadow-md"
      id="floating-right-bar"
    >
      <button
        className=" buttonFloatingCart flex text-white jsutify-center items-center pl-5 "
        onClick={handleShow}
      >
        <FaCartArrowDown
          id="arrowButton"
          className={"transition-all duration-1000 text-figma-text-hover"}
        />
      </button>
      <main className="cart-main grid w-full h-full">
        <div className="cart-topbar" />
        <div className="cart-cart max-h-[80%] overflow-y-auto custom-scrollbar">
          {cart.map((product) => {
            console.log(product);
            return (
              <div className="cart-cart-item text-lg" key={product.id}>
                <img
                  className="cart-cart-item-image"
                  src={product.image}
                  alt={product.name}
                />
                <h1>
                  {`${product.quantity} ${product.type} `} {product.name}
                </h1>
                <p>${product.price}</p>

                <div className="cart-cart-item-buttonarea">
                  <button className="cart-item-btn">
                    <FiEdit />
                  </button>
                  <button
                    className="cart-item-btn"
                    onClick={() => {
                      removeFromCart(product.id);
                    }}
                  >
                    <FiTrash />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cart-bottom flex-col gap-y-4  ">
          <span className="px-2 tracking-wider text-lg">
            Total: <span className="font-bold">$ {calculateTotal()}</span>
          </span>
          <button
            className="bg-red-600 w-full py-2 rounded-lg text-xl"
            onClick={clearCart}
          >
            Eliminar todos
          </button>
          <button className="bg-figma-primary w-full py-2 rounded-lg text-xl">
            Finalizar compra
          </button>
        </div>
      </main>
    </div>
  );
};
